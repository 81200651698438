const {
  APIScrapeProduct,
  APIFetchScrapeProduct,
  APISearchProduct,
} = require("API/product");
const { Http_request_get } = require("Service/HttpService");

export const scrapeProduct = async (url) => {
  let result = await Http_request_get(`${APIScrapeProduct}?targetUrl=${url}`);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchProduct = async (id) => {
  let result = await Http_request_get(APIFetchScrapeProduct + `/${id}`);
  if (!result.data) throw new Error("查無商品");
  return result.data;
};

export const searchProduct = async (searchText, platform, page) => {
  const qString = new URLSearchParams({ searchText, platform, page });
  const result = await Http_request_get(
    `${APISearchProduct}?${qString.toString()}`
  );
  if (!result.data)
    throw new Error(`Fail search ${platform} for ${searchText}`);
  return result.data;
};

export const calLimitAmt = (pickSpec, { specGroup, noSpecStock }) => {
  // If need specification but not choose yet,return 1
  if (specGroup && pickSpec < 0) return 1;

  // If no specification, return stock without spec, and max amount is 5, default amount is 1
  if (!specGroup) return noSpecStock > 5 ? 5 : noSpecStock ?? 1;

  // default amount 1
  const targetStock = specGroup[pickSpec]?.stock ?? 1;

  return targetStock > 5 ? 5 : targetStock;
};

export const calPrice = (pickSpec, amount, { specGroup, price }) => {
  if (pickSpec < 0 || (!pickSpec && pickSpec != 0)) {
    let p = price?.special || price.original;
    return price.addService * amount;
  }

  return specGroup[pickSpec].addService * amount;
};
