import { useEffect, useMemo, useState, useRef } from "react";
import { OverflowText } from "Components/Text/OverflowText";
import {
  Stack,
  Image,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Spinner,
  Card,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import {
  calLimitAmt,
  calPrice,
  fetchProduct,
  scrapeProduct,
  searchProduct,
} from "./utils";
import { useErrorMsg } from "Components/ErrorMsg/ErrorMsg";
import { AmtContrl } from "Components/Order/ScrapeOrder";
import "./component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faSearch,
  faShoppingCart,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import {
  useImgaeLoadObserver,
  useIntersectionObserver,
  useIO,
} from "Hook/useIntersectionObs";
import { RefundNotice } from "Components/Note/Product";
import { OrderFAQ } from "Components/FAQ/FAQ";

export const ProductStickyNoteWithButton = ({ product, children }) => {
  return (
    <Stack direction="horizontal" className="my-2">
      <Image src={product.basic.images[0]} className="ownCoupon-image" />
      <div
        className="ownCoupon-content"
        style={{ width: "300px", boxShadow: "5px" }}
      >
        <Row className="align-items-center">
          <Col>
            <strong>{product.basic.productName}</strong>
            <OverflowText text={product.basic.description} max={3} />
          </Col>
          <Col xs="auto">{children}</Col>
        </Row>
      </div>
    </Stack>
  );
};

export const ProductStickyNote = ({ product }) => {
  return (
    <Stack direction="horizontal" className="my-2">
      <Image src={product.basic.images[0]} className="ownCoupon-image" />
      <div
        className="ownCoupon-content"
        style={{ width: "300px", boxShadow: "5px" }}
      >
        <Row className="align-items-center">
          <Col>
            <strong>{product.basic.productName}</strong>
            <OverflowText text={product.basic.description} max={3} />
          </Col>
        </Row>
      </div>
    </Stack>
  );
};

export const SpecSelector = ({ specGroup = [], pickSpec, setPickSpec }) => {
  return (
    <Form.Select
      aria-label="Default select example"
      value={pickSpec}
      onChange={(e) => setPickSpec(e.target.value)}
      className="spec-select"
    >
      <option value="-1" disabled>
        請選擇規格
      </option>
      {specGroup?.map((o, idx) => (
        <option key={idx} value={idx} disabled={o?.stock < 1}>
          {Object.values(o.specs).sort().join(" | ")}
          {o?.stock < 1 && "(售完)"}
        </option>
      ))}
    </Form.Select>
  );
};

export const ScrapeProductSearchResult = ({
  data,
  handlePay,
  giftSpreadDiscount,
}) => {
  const {
    sellerData,
    productName,
    images,
    price,
    specGroup,
    noSpecStock,
    giveaways,
    customOptions,
    detailHtml,
  } = data;
  const [pickSpec, setPickSpec] = useState(-1);
  const [pickGiveaways, setPickGiveaways] = useState([]);
  const [pickCustomOptions, setPickCustomOptions] = useState([]);
  const [amount, setAmount] = useState(1);
  const [detailCollapse, setDetailCollapse] = useState(false);
  const errMsg = useErrorMsg();

  // cal final price
  const fPrice = useMemo(
    () => calPrice(pickSpec, amount, { specGroup, price }),
    [pickSpec, amount]
  );

  // cal quantity that is able to buy
  const limitAmount = useMemo(
    () => calLimitAmt(pickSpec, { specGroup, noSpecStock }),
    [pickSpec]
  );

  // change amount when limit amount smaller than choose amount
  useEffect(() => {
    limitAmount < amount && setAmount(1);
  }, [limitAmount]);

  // init giveaways
  useEffect(() => {
    if (!giveaways) return;
    setPickGiveaways(Array(giveaways.length).fill(0));
  }, []);

  // go to pay
  const handleSubmit = () => {
    errMsg.setMsg("");

    try {
      if (specGroup && pickSpec < 0) throw new Error("請選擇規格");

      if (fPrice < 5) throw new Error("價格錯誤");

      if (amount < 1) throw new Error("數量至少1個");

      handlePay({ pickSpec, amount, productData: data });
    } catch (err) {
      errMsg.setMsg(err.message);
    }
  };

  return (
    <>
      {/* <Stack>
        <a href={data?.originalUrl} target="_blank">
          來源商品
        </a>
      </Stack> */}
      <Row xs={1} md={2} className="search-result">
        <Col xs="auto" className="my-2">
          <Swiper
            className="search-result-swiper-container"
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={20}
            pagination={{ dynamicBullets: true, clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            {images.map((image, idx) => (
              <SwiperSlide key={idx} className="product-img-container">
                <Image src={image} className="product-img" rounded />
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
        <Col className="my-2">
          <errMsg.MsgAlert />
          <Stack gap={2}>
            <h5 className="text-primary">{sellerData.name}</h5>
            <h5>{productName}</h5>
            <section className="price-sec large-total-price">
              {/* {difPriceGroup && } */}
              {giftSpreadDiscount > 0 ? (
                <strong>
                  NT$
                  {fPrice - giftSpreadDiscount > 0
                    ? fPrice - giftSpreadDiscount
                    : 0}
                </strong>
              ) : (
                <strong>NT${fPrice}</strong>
              )}
            </section>
            {specGroup && (
              <SpecSelector
                specGroup={specGroup}
                pickSpec={pickSpec}
                setPickSpec={setPickSpec}
              />
            )}
            <AmtContrl
              value={amount}
              max={limitAmount}
              mCb={() => {
                if (amount - 1 < 1) return;
                setAmount((pre) => pre - 1);
              }}
              pCb={() => {
                if (amount + 1 > limitAmount) return;
                setAmount((pre) => pre + 1);
              }}
            />
          </Stack>
          <br />
          <Stack className="ms-auto" gap={2}>
            <Button
              className="go-to-pay-btn"
              variant="primary"
              onClick={handleSubmit}
              disabled={limitAmount < 1}
            >
              <FontAwesomeIcon icon={faShoppingCart} />{" "}
              {`${
                limitAmount === 0
                  ? "完售"
                  : giftSpreadDiscount > 0
                  ? "更換禮物"
                  : "購買禮物"
              }`}
            </Button>
          </Stack>
        </Col>
      </Row>
      <br />
      <Tabs fill defaultActiveKey={"p"} className="sticky-top bg-white">
        <Tab eventKey={"p"} title="商品資訊" className="py-3">
          {detailHtml && (
            <>
              <section
                className="product-info-detail style-3"
                style={{
                  height: detailCollapse ? "auto" : "250px",
                  overflow: "hidden",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailHtml.replace(/\n/g, "<br>"),
                    // __html: detailHtml,
                  }}
                />
              </section>
              <Stack
                className="mx-auto text-center"
                style={{ marginTop: "-10px" }}
              >
                <h5>
                  <Badge
                    bg="secondary"
                    // text="dark"
                    pill
                    onClick={() => setDetailCollapse(!detailCollapse)}
                  >
                    {detailCollapse ? "顯示更少" : "顯示更多"}
                  </Badge>
                </h5>
              </Stack>
            </>
          )}
        </Tab>
        <Tab eventKey={"o"} title="訂單須知" className="py-3">
          <RefundNotice />
          <br />
          <OrderFAQ />
        </Tab>
      </Tabs>
    </>
  );
};
//   {"storeName": storeName, "productName": productName, "allSpec":all_spec, "difPriceGroup":dif, "images": imgs, "price": price}

export const SearchProductBar = ({
  initValue = "",
  searchCb,
  handlePending,
}) => {
  const [typeText, setTypeText] = useState(initValue);
  const [pending, setPending] = useState(false);
  const errMsg = useErrorMsg();

  useEffect(() => {
    if (initValue) handleSubmit(initValue);
  }, []);

  const handleSubmit = async (searchText = typeText) => {
    errMsg.setMsg("");

    try {
      handlePending.setPending(true);
      setPending(true);

      let result;

      if (/[a-zA-Z0-9]{24}/.test(searchText)) {
        let data = await fetchProduct(searchText);
        result = { data, for: "fetch", searchText };
        searchCb(result);
        return;
      }

      if (/https?:\/\/\S+/.test(searchText)) {
        console.log(`into scrape product`);
        let data = await scrapeProduct(searchText);
        result = { data, for: "product", searchText };
        searchCb(result);
        return;
      }

      ["momo", "pinkoi"].forEach((e) => {
        searchProduct(searchText, e).then((data) => {
          result = { data, for: "products", searchText };
          searchCb(result);
        });
      });
    } catch (err) {
      errMsg.setMsg(err.message);
    } finally {
      handlePending.setPending(false);
      setPending(false);
    }
  };

  return (
    <Stack className="col-md-10 mx-auto">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <InputGroup>
          <Form.Control
            size="lg"
            type="search"
            autoComplete="off"
            placeholder="搜尋(e.g https://momo)"
            onChange={(e) => setTypeText(e.target.value)}
            value={typeText}
            id="search-input"
            className="shadow border border-white"
            style={{
              fontSize: "14px",
              color: "black",
              borderRadius: "18px",
            }}
          />
          <Button
            type="submit"
            disabled={pending}
            variant="primary"
            className="search-btn mx-1"
            style={{ borderRadius: "20px" }}
            // onClick={() => handleSubmit(targetUrl)}
          >
            {pending ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <FontAwesomeIcon icon={faSearch} style={{ color: "#fff" }} />
            )}
          </Button>
        </InputGroup>
        <section className="my-2">
          <errMsg.FormTextAlert />
        </section>
      </Form>
    </Stack>
  );
};

export const PlatformBadge = ({ platform }) => {
  const platformColor = {
    pinkoi: "#10567B",
    momo: "#D62872",
    linegift: "#06C755",
  };

  return (
    <span
      className="badge"
      style={{ backgroundColor: platformColor[platform] }}
    >
      {platform === "linegift" ? "LINE" : platform}
    </span>
  );
};

export const ProductSearchItem = ({ data, cb, giftSpreadDiscount = 0 }) => {
  const [ref, isIntersecting] = useIO({ rootMargin: "0px 0px 300px 0px", threshold: 0.1 });

  const finalPrice = useMemo(() => {
    return `${data.price - giftSpreadDiscount}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  }, [data?.price, giftSpreadDiscount]);

  return (
    <Card
      ref={ref}
      id={data.productLink}
      className="border-0 search-product-item text-dark"
      onClick={() => cb(data)}
    >
      {isIntersecting ? (
        <Image
          src={data.imgs?.[0]}
          className="lazy-load mx-auto search-product-img"
        />
      ) : (
        <Stack className="mx-auto">
          <Spinner />
        </Stack>
      )}

      <Card.Body>
        {/* <div>
          <PlatformBadge platform={data.platform} />
        </div> */}
        <small className="two-line-ellipsis">{data.productName}</small>
        {giftSpreadDiscount > 0 ? (
          <>
            <small>
              <Badge bg={finalPrice > 0 ? "primary" : "success"}>
                <FontAwesomeIcon icon={faTag} />
                {finalPrice > 0 ? "加購價" : "免費更換"}
              </Badge>
            </small>
            <Stack direction="horizontal" gap={1}>
              <h5 style={{ fontWeight: "bold" }}>
                NT${finalPrice > 0 ? finalPrice : 0}
              </h5>
              {/* <h6 className="text-decoration-line-through text-muted">
                NT${data.price}
              </h6> */}
            </Stack>
          </>
        ) : (
          <Card.Text>NT${finalPrice}</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};
