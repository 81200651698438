import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router-dom";

import { useUser } from "../Provider/UserProvider";

const User_page = ({ route }) => {
  const { user, hasToken, redirectAfterLogin } = useUser();
  const location = useLocation();

  useEffect(() => {
    if (!hasToken) {
      redirectAfterLogin();
    }
  }, [location]);

  // if (!hasToken) redirectAfterLogin();

  if (!user?._id) {
    return (
      <Row className="justify-content-center">
        <Col xs="auto">
          <Spinner animation="border" />
        </Col>
      </Row>
    );
  }

  return <div>{renderRoutes(route.routes)}</div>;
};

export default User_page;
